import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import targetZoneData from "../../../core/static/goal/targetZone.json";
import useJsonParse from "../../../hooks/useJsonParse";
import CheckboxCard from "../../UI/checkboxCard/CheckboxCard";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./TargetZone.module.scss";
import LazyImage from "../../UI/lazyImage/LazyImage";

const TargetZone = props => {
    const { nextStep, data } = props;
    const [cards, setCards] = useState();
    const [activeCards, setActiveCards] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const parsedData = useJsonParse(targetZoneData);
    const pageInfo = data.settings.targetZone;
    const themeData = data.themeData

    const selectCard = card => {
        if(card.checked) {
            setActiveCards((prev => [...prev, card]))
        } else {
            const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
            setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
        }
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_target_zone_appeared');
    }, [])

    useEffect(() => {
        if(activeCards.length !== 0) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [activeCards])
    
    useEffect(() => {
        setCards(parsedData);
    }, [parsedData]);

    const clickContinue = () => {
        const answerValue = activeCards.map(card => { return card.title})
        amplitude.getInstance().logEvent('button_onboarding_target_zone_continue_tapped', {answer: answerValue});
        const answer = new Map();
        answer.set("key", "target_zone");
        answer.set("value", answerValue.join(', '));
        nextStep(answer);
    }

    return (
        <>
            { pageInfo.absoluteBackgroundImage &&
                (<LazyImage
                    className={styles.absoluteBackground}
                    srcWebp={pageInfo.absoluteBackgroundImageWebp}
                    src={pageInfo.absoluteBackgroundImage}
                    alt=""
                />)
            }
            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.cardTitle}>{strings.goal.targetZone.title}</h1>
                <span className={`${styles.marginTitle} componentSubtitle`}>
                    {strings.goal.targetZone.subtitle}
                </span>
                <div className={`${styles.cardsContainer} flex-column cardsContainer`}>
                    <div className={styles.cardsImage}>
                        <LazyImage
                            className={styles.image}
                            srcWebp={pageInfo.cardsImageWebp}
                            src={pageInfo.cardsImage}
                            style={pageInfo.cardsImageStyle}
                            alt=""
                        />
                    </div>
                    <div className={`${styles.cardsWrapper} flex-column`}>
                        { cards?.map(card => (
                            <CheckboxCard
                                key={card.id}
                                data={card}
                                smallCard={true}
                                selectCard={selectCard}
                                checkboxColor={themeData.checkboxCardColor}
                            />
                        )) }
                    </div>
                    <ContinueButton
                        nextStep={clickContinue}
                        disableButton={disableButton}
                        theme={themeData.continueButton}
                    />
                </div>
            </div>
        </>
    )
}   

export default TargetZone;