import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
    en: {
        introPrefix: "Get a new you in 4 weeks",
        introH1: "Join Your Dancing Weight Loss Challenge",
        introSubtitle: "To start please select your main goal:",
        introSubtitleLoading: "Take the FREE quiz to get your personalized dance plan.",
        introLoadingText: "Loading the quiz ",
        introAgreements: "By continuing you agree to",
        introRights: "All rights reserved.",
        continue: "Continue",
        wait: "Please wait...",
        imgs: {
            "about-result-image-bf": "/images/result/about-result-image-bf.png",
            "about-result-image-bfWebp": "/images/result/about-result-image-bf.webp",
            "about-result-image-ny": "/images/result/about-result-image-ny.png",
            "about-result-image-nyWebp": "/images/result/about-result-image-ny.webp",
            "black-friday-icon-ret70": "/images/paywall/black-friday-icon-ret70.png",
            "black-friday-icon-ret70Webp": "/images/paywall/black-friday-icon-ret70.webp",
            "black-friday-icon": "/images/paywall/black-friday-icon.png",
            "black-friday-iconWebp": "/images/paywall/black-friday-icon.webp",
            "bf_icon_disc_75": "/images/paywall/bf_icon_disc_75.png",
            "bf_icon_disc_75Webp": "/images/paywall/bf_icon_disc_75.webp",
            "bf_icon_disc_60": "/images/paywall/bf_icon_disc_60.png",
            "bf_icon_disc_60Webp": "/images/paywall/bf_icon_disc_60.webp",
            "bf_icon_trial": "/images/paywall/bf_icon_trial.png",
            "bf_icon_trialWebp": "/images/paywall/bf_icon_trial.webp"
        },
        intro: {
         backgroundImage: null,
         backgroundImageWebp: null,
         mainGoal:{
           stayFit: {text: "Stay fit", fontSize: '23px'},
           loseWeight: {text: "Lose weight", fontSize: '23px'},
           burn: {text: "Burn calories", fontSize: '23px'},
           feel: {text: "Feel amazing & confident", fontSize: '23px'},
           dance: {text: "Dance & having fun", fontSize: '23px'},
         }
        },
        goal: {
            label: "GOAL",
            bannerTitle: "1 million people",
            bannerSubtitle: " have chosen Everdance",
            mainGoal:{
                labels: {
                    stayFit: "Stay fit",
                    loseWeight: "Lose weight",
                    burn: "Burn calories",
                    feel: "Feel amazing & confident",
                    dance: "Dance & having fun",
                }
            },
            motivation: {
                        title: "What motivates you the most?",
                        subtitle: "This will help us find the best program for you.",
                        labels: {
                            healthy: "Lead a healthy life",
                            enjoy: "Enjoy yourself",
                            lookBetter: "Look better",
                            reduceStress: "Reduce stress",
                            improveSE: "Improve self-esteem"
                        }
            },
            targetZone: {
                title: "Choose your target zone",
                subtitle: "Our workout dance program will focus on that area",
                labels: {
                    arms: "Arms",
                    chest: "Chest",
                    belly: "Belly",
                    hips: "Hips",
                    legs: "Legs",
                }
            },
            currentBody: {
                multicolorHeader: {
                    titleOne: "Choose your",
                    titleTwo: " current",
                    titleThree: " body shape",
                },
                title: "Choose your body shape",
                plump: "Plump",
                skinny: "Skinny"
            },
            targetBody: {
                titleStart: " Choose your",
                titleEnd: " body shape",
                titleDesired: " desired",
                plump: "Plump",
                skinny: "Skinny",
                medium: "Medium",
                curvy: "Curvy"
            },
            result: {
                reviewTitle: "Thanks for changing my life",
                reviewText: "Guys, you're freaking amazing! I never would've thought that dancing could finally help me lose weight without any grueling workouts and giving me immense pleasure from new moves.",
                reviewAuthor: "Ashley Townsend"
            }
        },
        about: {
            label: "ABOUT YOU",
            age: {
             title: "How old are you?",
             subtitle: "This will help us made adjustment to your personal plan.",
             age: "AGE"
            },
            height: {
              title: "Your height",
              subtitle: "This will allow us define your body mass index and adapt your workout plan.",
              ft: "FT",
              in: "IN",
              cm: "CM",
              errorGreater: "Value must be greater than or equal to ",
              errorLess: "Value must be less than or equal to "
            },
            currentWeight: {
              title: "Your current weight",
              subtitle: "This will allow us define your body mass index and adapt your workout plan.",
              lbs: "LBS",
              kg: "KG"
            },
            goalWeight: {
              title: "Your goal weight",
              subtitle: "This will allow us define your body mass index and adapt your workout plan.",
              lbs: "LBS",
              kg: "KG"
            },
            errors: {
              numeric: "Numeric value only",
              errorGreater: "Value must be greater than or equal to ",
              errorLess: "Value must be less than or equal to ",
              errorCurrent: "Value must be less than the current weight you entered",
            },
            result: {
              title: "Women in their ",
              subtitleStart: "Who aim to weight ",
              subtitleEnd: " remember: care & attention should become an integral part of their daily workouts. Don't forget about the health and harmony of your body!"
            }
        },
        lifestyle: {
            label: "LIFESTYLE",
            activity: {
                title: "What’s your activity level?",
                subtitle: "Your program will be adjusted to your current lifestyle.",
                levels: {
                    sedentary: {
                        title: "Sedentary",
                        description: "You get no formal exercise and are not physically active during the day"
                    },
                    lightly: {
                        title: "Lightly active",
                        description: "You perform lifestyle activities, like taking the dog for a walk or gardening"
                    },
                    moderate: {
                        title: "Moderately active",
                        description: "You participate in cardio exercises for 20 to 60 minutes, 3 to 5 days per week"
                    },
                    high: {
                        title: "Highly active",
                        description: "You exercise for 20 to 60 minutes most days a week"
                    }
                }
            },
            busy: {
                title: "How busy are you on an average day?",
                subtitle: "This will help us find the best program for you.",
                labels: {
                    barelyHaveTime: "I barely have any time for myself",
                    busy: "I’m busy, but I save some time for myself",
                    notBusy: "I’m not too busy",
                    flexible: "My schedule is flexible"
                }
            },
            children: {
               title: "Do you have children?",
               subtitle: "This will help us find the best program for you.",
               labels: {
                         yes:  {
                            text: "Yes",
                            subtext: "Great!!! Dance is a perfect way to improve relationship with your child"
                         },
                         no: "No"
                 }
            },
            diet: {
               title: "How would you describe your diet?",
               subtitle: "This will allow us to prepare useful recommendations for you.",
               labels: {
                  healthy: {
                      text: "Healthy",
                      subtext: "I eat a bit of everything and not too much junk food"
                  },
                  not_good: {
                      text: "It could be better",
                      subtext: "I tend to be attracted by greasy food and don't each many vegetables"
                  },
                  poor: {
                      text: "Poor diet",
                      subtext: "I eat mostly greasy food and very few vegetables"
                  }
               }
            },
            result: {
                title: "Sticking to a plan can be hard. Everdance makes it easy.",
                comment: "“Everdance made dancing my daily routine and saved me time on going to workouts. I can now train from home and prioritize self-care.”",
                commentAuthor: "- Ashley",
                commentHint: {
                    start: "*Everdance users report positive changes",
                    middle: " after 1-week ",
                    end: "of use."
                }
            }
        },
        nutrition: {
            label: "NUTRITION",
            breakfast: {
                title: "When do you usually have breakfast?",
                sixEight: {
                    text: "Between 6 and 8 am", fontSize: '16px'
                },
                eightTen: {
                    text: "Between 8 and 10 am", fontSize: '16px'
                },
                tenNoon: {
                    text: "Between 10 am and noon", fontSize: '16px'
                },
                skip: {
                    text: "I usually skip breakfast", fontSize: '16px'
                }
            },
            lunch: {
                title: "How about lunch?",
                tenNoon: {
                    text: "Between 10 am and noon", fontSize: '16px'
                },
                noonTwo: {
                    text: "Between noon and 2 pm", fontSize: '16px'
                },
                twoFour: {
                    text: "Between 2 and 4 pm", fontSize: '16px'
                },
                skip: {
                    text: "I usually skip lunch", fontSize: '16px'
                }
            },
            dinner: {
                title: "What time do you have dinner?",
                fourSix: {
                    text: "Between 4 and 6 pm", fontSize: '16px'
                },
                sixEight: {
                    text: "Between 6 and 8 pm", fontSize: '16px'
                },
                eightTen: {
                    text: "Between 8 and 10 pm", fontSize: '16px'
                },
                skip: {
                    text: "I usually skip dinner", fontSize: '16px'
                }
            },
            typeOfDiet: {
                title: "What type of diet do you prefer?",
                withMeat: "WITH MEAT",
                withoutMeat: "WITHOUT MEAT",
                withFish: "WITH FISH",
                withoutAllergens: "WITHOUT ALLERGENS",
                labels: {
                    withMeatTraditional: {
                        text: "Traditional",
                        subtext: "I enjoy everything"
                    },
                    withMeatKeto: {
                        text: "Keto",
                        subtext: "I prefer high-fat low-carb meals"
                    },
                    withMeatPaleo: {
                        text: "Paleo",
                        subtext: "I don’t eat processed foods"
                    },
                    withoutMeatVegetarian: {
                        text: "Vegetarian",
                        subtext: "I avoid meat and fish"
                    },
                    withoutMeatVegan: {
                        text: "Vegan (Plant Diet)",
                        subtext: "I do not eat animal products"
                    },
                    withoutMeatKetoVegan: {
                        text: "Keto Vegan",
                        subtext: "I eat low-carb plant-based meals only"
                    },
                    withFishMediterranean: {
                        text: "Mediterranean",
                        subtext: "I eat plenty of veggies, grains and seafood"
                    },
                    withFishMescatarian: {
                        text: "Pescatarian",
                        subtext: "I avoid meat but enjoy fish"
                    },
                    withoutAllergensLactoseFree: {
                        text: "Lactose Free",
                        subtext: "I do not consume foods with lactose"
                    },
                    withoutAllergensGlutenFree: {
                        text: "Gluten Free",
                        subtext: "I avoid gluten-containing grains"
                    }
                }
            },
            badHabits: {
                title: "Do you have any of the following bad habits?",
                subtitle: "Choose all that apply",
                labels: {
                    emotional: "Emotional or boredom eating",
                    overeating: "Overeating",
                    snacking: "Late-night snacking",
                    skipping: "Skipping meals too often",
                    none: "None of the above"
                }
            },
            craveFoods: {
                title: "What foods do you crave most often?",
                subtitle: "Choose all that apply",
                labels: {
                    sweet: "Sweet treats",
                    salty: "Salty snacks",
                    fast: "Fast food",
                    soda: "Soda",
                    none: "None of the above"
                }
            },
            nutritionResult: {
                title: "Reminder",
                comment: "“80% of your results comes from the food you eat, and only 20% of your results come from your workouts.”",
                note: "*Results are not guaranteed. People who use Everdance can expect to lose 1-2 pounds per week.",
                feedbacks: {
                    genia: {
                        text: "“I’ve lost 9 kg to date and I think Everdance is going to change my life forever.”",
                        name: "- Genia"
                    },
                    sonya: {
                        text: "“Thanks to Everdance I’ve toned my body and changed my habits.”",
                        name: "- Sonya"
                    },
                    zhang: {
                        text: "“I’ve boosted my energy in a new level with good meals and regular dance workouts.”",
                        name: "- Zhang"
                    },
                }
            }
        },
        plan: {
            label: "YOUR PLAN",
            experience: {
            title: "What would best describe your dance experience?",
            subtitle: "This will help us to improve your user experience in Everdance.",
            labels: {
                 beginner: {
                     text: "Beginner",
                     subtext: "Just starting"
                 },
                 intermediate: {
                     text: "Intermediate",
                     subtext: "I dance occasionally"
                 },
                 advanced: {
                     text: "Advanced",
                     subtext: "I take dancing classes"
                 }
              }
            },
            styles: {
                title: "Which dance styles do you want to work on?",
                subtitle: "This will help to take into account your preferences in styles when making recommendations.",
                labels: {
                    hiphop: "Hip-Hop",
                    kpop: "K-Pop",
                    dancehall: "Dancehall",
                    latino: "Solo Latino",
                    belly: "Belly Dance",
                    tiktok: "Tik-Tok",
                    vogue: "Vogue",
                    twerk: "Twerk",
                    heels: "Heels",
                    jazzfunk: "Jazz-funk"
                },
                donotknow: "I don't know these styles"
            },
            times: {
               title: "How many times a week would you like to dance?",
               labels: {
                0: "1-2",
                1: "3-4",
                2: "5 or more"
               }

            },
            session: {
              title: "How long would you like your dance session to be?",
              labels: {
                 0: "10-15 min",
                 1: "20-30 min",
                 2: "35-45 min"
              }
            },
            danceExercise:{
                 title: "Dance vs Regular exercises",
                 subtitle: "Average calories burned in 30 min",
                 info: "“If we look at the heart-rate monitor strips from the Zumba fitness session... you burn extra calories compared to a steady-state exercise”"
            },
            chartBanner:{
                title: "Your 4-week Dancing Weight Loss Plan is ready!",
                subtitle: "*based on the information of users who log their progress in the app",
                info: "<b>65%</b> of users who started their <b>Dancing Weight Loss Plan with Everdance</b> advanced in their goals within the <b>first month*</b>"
            },
            creatingPlan: {
                title: "The most innovative technology in the market.",
                subtitle: "Creating your personalized plan...",
                messages: [
                    {
                        firstTitle: "Personal",
                        secondTitle: " dance plan ",
                        thirdTitle: "according to your goals"
                    },
                    {
                        firstTitle: "Track your progress and",
                        secondTitle: " get achievements",
                        thirdTitle: ""
                    },
                    {
                        firstTitle: "Calories burned",
                        secondTitle: " AI ",
                        thirdTitle: "tracker"
                    }
                ]
            },
            resultProgress: {
                title: "We’ve got you! Let’s tailor your workout program to your fitness profile",
            },
            result: {
                title: "1 million people",
                subtitle: "Trusted by over 1,423,658 users",
                processTitle: "Creating your plan...",
                labels: {
                    25: "Scanning your goals",
                    50: "Analyzing body parameters",
                    75: "Choosing workouts to your needs",
                    100: "Generating your action plan"
                },
                comments: {
                   0: {
                    title: "Amazing!",
                    subtitle: "I’ve lost 9 kg to date and I think Everdance is going to change my life forever.",
                    author: "Genia Johansen",
                   },
                   1: {
                    title: "I love this app",
                    subtitle: "Thanks to Everdance I’ve toned my body and changed my habits",
                    author: "Olivia Emma",
                   },
                   2: {
                    title: "Superb application",
                    subtitle: "I’ve boosted my energy in a new level with good meals and regular dance workouts.",
                    author: "Ummu Yilmaz"
                   }
                }
            }
        },
        paywall: {
          greenTheme: {
              beforeTitle: "You can reach your goal",
              title: "with Personal Dance Plan",
              chartNotice: "This chart is for illustrative purposes only",
              mealPlan: {
                  titleOne: "Weight Loss Boost",
                  titleTwo: "Speed up your results with our",
                  titleThree: "Meal Plan & HIIT Workouts Program",
                  timer: "EXPIRES IN:",
                  stickyTimer: "Free bonus enhancement offer expires in",
              },
              cards: {
                  titleOne: "Grab your Personal Dance Plan",
                  titleTwo: "+ Weight Loss Boost",
                  titleThree: "before it’s gone!",
                  notifyBox: {
                      mainText: "People using Everdance for 3 months lose twice as much weight as for 1 month*",
                      subText: "*According to Everdance user research, 2023",
                      notifyText: "*You can cancel anytime. All prices are in USD."
                  }
              },
              downloadApps: {
                  title: "1,4+ Million",
                  subtitle: "users started their weight loss journey with us",
              },
              stayOnTop: {
                  titleOne: "Stay on top of your dance journey with ",
                  titleTwo: "the Everdance app",
              }
          },
          reserved: "Reserved price for:",
          title: {
             start: "Your ",
             personal: "personalized",
             end: " 30-day program is ready"
          },
          products: {
          "1-month plan": "1-month plan",
          "3-month plan": "3-month plan",
          "1-week plan": "1-week plan",
          "6-month plan": "6-month plan",
          },
          periods: {
             "3 months": "3 months ",
             "week": "week ",
             "1 month": "1 month ",
             "6 months": "6 months ",
          },
          goal: "Goal",
          targetWeight: "Target weight",
          cancelAnytime: "*You can cancel anytime. All prices are in USD.",
          peopleAsk: "People Often Ask",
          love: "Users love our plans",
          get: "What you get",
          features: {
            0: {
            p1: "Full access to",
            p2: "350+ dance ",
            p3: "classes"
            },

            1: {
            p1: "Personal ",
            p2:  "dance",
            p3:  "plan"
            },
            2: "Special prize for our subscribers",
            3: "Live dance classes 2 times a week",
            4: "Daily useful tips&tricks",
            5: {
            p1: "Feedback from ",
            p2: " dance instructors"
            },
            6: {
            p1: "Just 15 minutes per ",
            p2: "day to see ",
            p3: " noticeable results"
            },
            7: "Nike Dancers and Lizzo’s Grrrls classes"
          },
          bonus: {
                            title: "+ FREE BONUS:",
                            mealPlan: "Meal Plan &",
                            hiit: "HIIT Workouts Program"
          },
          refund: {
            value: "Our users value us:",
            rate: " refund rate less than 2%*",
            year: "*based on 2022 data",
            days: "30-day ",
            mbg: "money back guarantee"
          },
          discount: {
                previous: "Previous discount: ",
                titleGet: "Get your personal plan with up to",
                titleStart: "Start your personal plan with",
                discount:  " discount"
          },
          cardHeader: {
               title: {
                   specialOffer: "SPECIAL OFFER",
                   bfOffer: "BLACK FRIDAY OFFER",
                   nyOffer: "NEW YEAR OFFER"
               },
               imageTitle: {
                      off50: {text: "50% OFF", fontSize: "16px"},
                      off60: {text: "60% OFF", fontSize: "16px"},
                      trial: {text: "7-DAY TRIAL", fontSize: "16px"},
                      off70: {text: "70% OFF", fontSize: "16px"},
                      off75: {text: "75% OFF", fontSize: "16px"},
                      bf: {text: "BLACK FRIDAY", fontSize: "16px"},
                      ny: {text: "NEW YEAR", fontSize: "16px"}
               },
               save: {text: "SAVE ", fontSize: "12px"},
               trial: {text: "7-DAY TRIAL", fontSize: "10px"},
               sale: {text: "SALE", fontSize: "16px"},
               mostPopular: {text: "MOST POPULAR", fontSize: "12px"},
               perDay: "per day"
          },

          questions: {
            0: {
              title: "Can I lose weight by dancing?",
              subtitle: "You bet! Dancing is a greate cardio workout that not only helps you burn calories, but also helps you feel happier. Lose weight and feel good doing it!"
            },
            1: {
              title: "How can dancing benefit me?",
              subtitle: "Dancing can help you lose excess weight, tone muscle, make you more flexible and improve cardiovascular health. Not to mention make you feel happier!"
            },
            2: {
               title: "Can I get a toned body by dancing?",
               subtitle: "A Dancer’s Body is a thing for a reason! By dancing regularly, you can impove your muscle tone, get rid of extra pounds, and improve self-esteem."
            },
            3: {
              title: "Why is dancing a good workout?",
              subtitle: "Dancing is amazing because it’s suitable for both pros and complete beginners in the sporting world. It requires absolutely nothing to start, and can give you real improvements."
            }
          },
          comments: {
            0: {
            title: "Amazing!",
            subtitle: "I never thought that training could be so fun and that I could loose so much weight with an app. I told my mom and now we train together and we love it.",
            author: "Emma Johansen",
            date: "21 January"
            },
            1: {
            title: "I love this app",
            subtitle: "Guys, much love and respect for what you do! It really helps to loose weight and have fun at the same time! Keep up the good work.",
            author: "Xavier",
            date: "5 November"
            },
            2: {
            title: "Thank you so much",
            subtitle: "Finally, I found an app that keeps me in shape and doesn’t let me get bored. I’ve been overweight for so long and tried and failed so many times I almost just gave up completely. But I have been genuinely surprised by this app. Lol, I’ve burn so many calories from this app by just dancing, I love it!",
            author: "Jaiah",
            date: "11 October"
            }
          },
          feedbacks: {
                              0: {
                                name: "Raquel",
                                before: "27 January, 2023",
                                after: "22 May, 2023",
                                weight: "- 22kg (48 Ib)",
                                feedback: "My story is a common one. I used to eat my stress away and ended up having to completely change my wardrobe. I hated my new self, my big belly, and I was angry. I tried new diets and going to the gym, but I kept falling off track. It wasn't until I started therapy with a psychologist who helped me realize the root of my stress and remember my childhood dreams. That's when I started dancing again. My dance journey is still ongoing, but I can already see the results and it inspires me even more! Plus, now I can never make an excuse that I don't have time to work out because with a mobile dance app, I can do it anywhere and choose dance styles that really excite me."
                              },
                              1: {
                                name: "Anne",
                                before: "1 May, 2023",
                                after: "15 August, 2023",
                                weight: "- 17kg (37 Ib)",
                                feedback: "I'm thrilled that I'm able to get back to my pre-baby shape so easily and enjoyably thanks to dance workouts. Fellow moms will understand how hard it is to find time for self-care when you have a screaming newborn and every day feels like Groundhog Day. But for the past few months, I've been dancing at home and it's been such a joy! Learning new dance moves, seeing my body become more flexible and toned, and watching my belly shrink. And most importantly, it's been improving my mood and rediscovering what my body is capable of!"
                              },
                              2: {
                                name: "Emma",
                                before: "5 April, 2023",
                                after: "23 June, 2023",
                                weight: "- 13kg (33 Ib)",
                                feedback: "I've always had a slim figure and could easily eat whatever I wanted without gaining weight. But that made me too relaxed about my habits. Over time, I started gaining weight without even realizing it, became too lethargic with no energy. That was until my doctor shocked me by saying that I'd develop diabetes if I didn't change my habits, add more physical activity.\nMy friend suggested trying an individual workout plan from a new dance app and working out at home whenever it's convenient for me. Within a couple of weeks, I started noticing my usual energy returning, my body becoming more toned, and I began smiling more."
                              }
          },
          featured: "As featured in:",
          usersMeetGoals: "Our Users Meet Their Goals",
          peopleLikeYou: "People just like you achieved great results using our ",
          plan: "Dancing Weight Loss Plan",
          disclaimer: "Disclaimer:",
          key: " Following Exercise and a meal plan is the key in your fitness journey and greatly the results. It’s unusual to lose more than 4 kg per month. Consult a physician first.",
          guarantee: "30-Day Money Back Guarantee",
          results: "We believe that our plan may work for you and you’ll get visible results in 4 weeks!",
          return: "We are even ready to return your money back if you don’t see visible results and can demonstrate that you followed our plan.",
          note: "Please note:",
          subscrRenew: " Subscriptions renew automatically at the end of each period unless you cancel it. If you want to cancel a subscription, write to our Support. Prepayment of total plan cost required. You will need an Android or iOS mobile phone to access the full version of the product. You may want to take a screenshot of this information and save it."
        },
        payment: {
              title: "SELECT PAYMENT METHOD",
              checkout: "Checkout",
              personalized: "Personalized Workout Plan",
              offer: "Introductory offer discount",
              youSave: "You save ",
              total: "Total",
              start: "Start your ",
              or:  "or",
              after: "After successful payment, please check your email.",
              afterFirst: {
               after: "After first ",
               per: " per "
              },
              cancelAny: "Cancel anytime",
              cardnumber: "Card number",
              cardname: "Cardholder name",
              email: "E-mail",
              perDay: " per day",
              success: "Payment was successful!",
              checkEmail: "Please check your email address specified during the payment."
        },
        registration: {
        steps: {
            0:  "Buy\nplan",
            1: "Create\nAccount",
            2: "Access\nplan",
            stepsWidth: '200px',
            fontSize: '14px'
        },
        title: "Create Account",
        subtitle: "to access plan",
        fullname: "Full Name",
        email: "Your email",
        password: "Password",
        confirmPassword: "Confirm Password",
        emailOk: "Current email available for registration",
        emailExists: "Current email already exists",
        emailErr: "Incorrect email value",
        passwordLength: "Password must be at least 6 characters",
        passwordMatch: "Please make sure your passwords match",
        create: "CREATE ACCOUNT",
        bySigning: "By signing up for Everdance, you agree to the",
        view: "View our",
        created: "Your account was created",
        nextSteps: "Follow this steps to get access to your plan",
        download: "Download the Everdance app",
        toPhone: "to your phone",
        use: "Use ",
        login: "Log in",
        toLogin: " to Log in",
        youShould: "You should tap to Log in and enter your email and password from the previous step",
        enter: "Enter your email and password from the previous step",
        }

    },
    ru: {
        introPrefix: "Новая ты за 4 недели",
        introH1: "Начни тацевальный челлендж по похудению",
        introSubtitle: "Для старта, выберите свою главную цель:",
        introSubtitleLoading: "Пройдите БЕСПЛАТНЫЙ тест, чтобы получить свой индивидуальный план занятий танцами.",
        introLoadingText: "Загрузка викторины",
        introAgreements: "Продолжая, вы соглашаетесь с:",
        introRights: "Все права защищены.",
        introGoalFit: "Быть в форме",
        introGoalLoseWeight: "Похудеть",
        continue: "Далее",
        wait: "Подождите...",
        imgs: {
         "about-result-image-bf": "/images/result/about-result-image-bf_ru.png",
         "about-result-image-bfWebp": "/images/result/about-result-image-bf_ru.webp",
         "black-friday-icon-ret70": "/images/paywall/black-friday-icon-ret70_ru.png",
         "black-friday-icon-ret70Webp": "/images/paywall/black-friday-icon-ret70_ru.webp",
        },
        intro: {
           backgroundImage: "/images/intro/intro_ru.png",
           backgroundImageWebp: "/images/intro/intro_ru.webp",
           mainGoal:{
               stayFit: {text: "Быть в форме", fontSize: '20px'},
               loseWeight: {text: "Похудеть", fontSize: '20px' },
               burn: {text: "Сжечь калории", fontSize: '23px'},
               feel: {text: "Чувствовать себя хорошо и уверенно", fontSize: '23px'},
               dance: {text: "Танцевать и веселиться", fontSize: '23px'},
             }
        },
        goal: {
            label: "ЦЕЛЬ",
            bannerTitle: "1 миллион человек",
            bannerSubtitle: " выбирают Everdance",
            mainGoal:{
                labels: {
                    stayFit: "Быть в форме",
                    loseWeight: "Похудеть",
                    burn: "Сжечь калории",
                    feel: "Чувствовать себя хорошо и уверенно",
                    dance: "Танцевать и веселиться",
                }
            },
            motivation: {
                        title: "Что мотивирует вас больше всего?",
                        subtitle: "",
                        labels: {
                            healthy: "Жить здоровой жизнью",
                            enjoy: "Наслаждаться собой",
                            lookBetter: "Выглядеть лучше",
                            reduceStress: "Снизить стресс",
                            improveSE: "Улучшить самооценку",
                        }
            },
            targetZone: {
                title: "Выберите проблемные зоны",
                subtitle: "Выберите проблемные зоны",
                labels: {
                    arms: "Руки",
                    chest: "Грудь",
                    belly: "Живот",
                    hips: "Бедра",
                    legs: "Ноги"
                }
            },
            currentBody: {
                multicolorHeader: {
                    titleOne: "Choose your",
                    titleTwo: " current",
                    titleThree: " body shape",
                },
                title: "Выберите свою текущую форму",
                plump: "Полный",
                skinny: "Худой"
            },
            targetBody: {
                titleStart: "Выберите свою ",
                titleEnd: "форму",
                titleDesired: "желаемую ",
                plump: "Полный",
                skinny: "Худой",
                medium: "Подтянутый",
                curvy: "Стройный"
            },
            result: {
                reviewTitle: "Вы изменили мою жизнь",
                reviewText: "Ребята, вы супер! Никогда бы не подумала, что танцы смогут наконец-то помочь мне похудеть без изнурительных тренировок и доставить колоссальное удовольствие от новых движений.",
                reviewAuthor: "Татьяна Воронцова"
            }
        },
        about: {
            label: "О ВАС",
            age: {
                title: "Сколько вам лет?",
                subtitle: "Это позволит нам внести изменения для вашего персонального плана.",
                age: "ЛЕТ"
            },
            height: {
                title: "Ваш рост",
                subtitle: "Это позволит нам определить индекс вашего тела и адаптировать ваш план",
                ft: "фт",
                in: "дм",
                cm: "см"
            },
            currentWeight: {
               title: "Ваш текущий вес",
               subtitle: "Это позволит нам определить индекс вашего тела и адаптировать ваш план",
               lbs: "фунт",
               kg: "кг"
            },
            goalWeight: {
               title: "Ваш целевой вес",
               subtitle: "Это позволит нам определить индекс вашего тела и адаптировать ваш план",
               lbs: "фунт",
               kg: "кг"
            },
            errors: {
               numeric: "Пожалуйста, вводите только цифры",
               errorGreater: "Значение должно быть больше или равно ",
               errorLess: "Значение должно быть меньше или равно ",
               errorCurrent: "Значение должно быть меньше, чем введенный вами текущий вес",
            },
            result: {
               title: "Девушки в свои ",
               subtitleStart: "Кто стремится к весу  ",
               subtitleEnd: " помните: забота и внимание должны стать неотъемлемой частью ваших ежедневных тренировок. Не забывайте о здоровье и гармонии своего тела!"
            }
        },
        lifestyle: {
            label: "ОБРАЗ ЖИЗНИ",
            activity: {
                title: "Насколько вы активны?",
                subtitle: "",
                levels: {
                    sedentary: {
                        title: "Сидячий",
                        description: "Вы не занимаетесь спортом и не ведете физическую активность в течение дня."
                    },
                    lightly: {
                        title: "Легкая активность",
                        description: " Например, гуляете с собакой или работаете в саду."
                    },
                    moderate: {
                        title: "Средняя активность",
                        description: "Вы занимаетесь кардиоупражнениями от 20 до 60 минут от 3 до 5 дней в неделю."
                    },
                    high: {
                        title: "Высокая активность",
                        description: "Вы тренируетесь от 20 до 60 минут большую часть дней в неделю."
                    }
                }
            },
            busy: {
                title: "Насколько вы заняты в течение дня?",
                subtitle: "",
                labels: {
                    barelyHaveTime: "У меня почти нет времени на себя",
                    busy: "Я занята, но я выделяю немного времени на себя",
                    notBusy: "Я не очень занята",
                    flexible: "Мое время очень гибкое"
                }
            },
            children: {
               title: "У вас есть дети?",
               subtitle: "",
               labels: {
                    yes:  {
                        text: "Да",
                        subtext: "Супер!!! Танец – идеальный способ улучшить отношения с ребенком"
                    },
                    no: "Нет"
               }
            },
            diet: {
                title: "Как вы опишете свое питание?",
                subtitle: "",
                labels: {
                     healthy: {
                                  text: "Здоровое",
                                  subtext: "Я ем всего понемногу и не слишком много вредной пищи"
                     },
                     not_good: {
                                  text: "Может быть лучше",
                                  subtext: "Меня, как правило, привлекает жирная пища, и я не ем достаточно овощей."
                     },
                     poor: {
                                  text: "Нездоровое питание",
                                  subtext: "Я ем в основном жирную пищу и очень мало овощей."
                     }
                }
            },
            result: {
                title: "Придерживаться плана может быть сложно, но с  Everdance это легко.",
                comment: "“Everdance сделал танцы моей повседневной жизнью и сэкономил мне время на походы в зал. Теперь я могу тренироваться дома и уделять себе больше времени.”",
                commentAuthor: "- Оксана",
                commentHint: {
                 start: "*Пользователи Everdance уже",
                 middle: " через 1 неделю ",
                 end: "замечают положительные изменения. "
               }
            }
        },
        nutrition: {
            label: "ПИТАНИЕ",
            breakfast: {
                title: "Во сколько вы обычно завтракаете?",
                sixEight: {
                    text: "Между 6 и 8 утра", fontSize: '16px'
                },
                eightTen: {
                    text: "Между 8 и 10 утра", fontSize: '16px'
                },
                tenNoon: {
                    text: "Между 10 утра и полуднем", fontSize: '16px'
                },
                skip: {
                    text: "Я обычно пропускаю завтрак", fontSize: '16px'
                }
            },
            lunch: {
                title: "Что насчет обеда?",
                tenNoon: {
                    text: "Между 10 утра и полуднем", fontSize: '16px'
                },
                noonTwo: {
                    text: "Между полуднем и 2 часами дня", fontSize: '16px'
                },
                twoFour: {
                    text: "Между 2 и 4 часами дня", fontSize: '16px'
                },
                skip: {
                    text: "Я обычно пропускаю обед", fontSize: '16px'
                }
            },
            dinner: {
                title: "В какое время вы ужинаете?",
                fourSix: {
                    text: "Между 4 и 6 вечера", fontSize: '16px'
                },
                sixEight: {
                    text: "Между 6 и 8 вечера", fontSize: '16px'
                },
                eightTen: {
                    text: "Между 8 и 10 вечера", fontSize: '16px'
                },
                skip: {
                    text: "Я обычно пропускаю ужин", fontSize: '16px'
                }
            },
            typeOfDiet: {
                title: "Какой тип питания вы предпочитаете?",
                withMeat: "С МЯСОМ",
                withoutMeat: "БЕЗ МЯСА",
                withFish: "С РЫБОЙ",
                withoutAllergens: "БЕЗ АЛЛЕРГЕНОВ",
                labels: {
                    withMeatTraditional: {
                        text: "Традиционный",
                        subtext: "Мне нравится все"
                    },
                    withMeatKeto: {
                        text: "Кето",
                        subtext: "Я предпочитаю высокожирную низкоуглеводную пищу"
                    },
                    withMeatPaleo: {
                        text: "Палео",
                        subtext: "Я не употребляю обработанную пищу"
                    },
                    withoutMeatVegetarian: {
                        text: "Вегетарианский",
                        subtext: "Я не употребляю мясо и рыбу"
                    },
                    withoutMeatVegan: {
                        text: "Веган (Растительная диета)",
                        subtext: "Я не употребляю животных продуктов"
                    },
                    withoutMeatKetoVegan: {
                        text: "Кето Веган",
                        subtext: "Я предпочитаю высокожирную низкоуглеводную пищу только растительного происхождения"
                    },
                    withFishMediterranean: {
                        text: "Средиземноморский",
                        subtext: "Я употребляю много овощей, злаков и морепродуктов"
                    },
                    withFishMescatarian: {
                        text: "Пескатарианский",
                        subtext: "Я не употребляю мясо, но добавляю в рацион рыбу"
                    },
                    withoutAllergensLactoseFree: {
                        text: "Безлактозный",
                        subtext: "Я не употребляю в пищу продукты с лактозой"
                    },
                    withoutAllergensGlutenFree: {
                        text: "Безглютеновый",
                        subtext: "Я избегаю продуктов, содержащих глютен"
                    }
                }
            },
            badHabits: {
                title: "У вас есть какие-нибудь вредные пищевые привычки?",
                subtitle: "Выберите все подходящие варианты",
                labels: {
                    emotional: "Эмоциональное заедание",
                    overeating: "Переедание",
                    snacking: "Ночные перекусы",
                    skipping: "Частые пропуски приемов пищи",
                    none: "Ничего из перечисленного"
                }
            },
            craveFoods: {
                title: "Какой пищи вам обычно больше всего хочется?",
                subtitle: "Выберите все подходящие варианты",
                labels: {
                    sweet: "Сладости",
                    salty: "Соленые перекусы",
                    fast: "Фаст-фуды",
                    soda: "Газированные напитки",
                    none: "Ничего из перечисленного"
                }
            },
            nutritionResult: {
                title: "Напоминание",
                comment: "“80% от вашего результата приходит от еды, которую вы едите, и только 20%  вашего результата появляются от тренировок.”",
                note: "*Результаты не гарантированы. Люди, использующие Everdance, могут ожидать потерю веса до 0.5-1 кг за неделю.",
                feedbacks: {
                    genia: {
                        text: "“Я скинула 9 кг к настоящему времени и  я думаю, что Everdance - это то, что изменит мою жизнь навсегда.”",
                        name: "- Евгения"
                    },
                    sonya: {
                        text: "“Спасибо Everdance! Я подтянула свое тело и изменила к лучшему свои привычки.”",
                        name: "- София"
                    },
                    zhang: {
                        text: "“Моя энергия значительно увеличилась благодаря хорошему питанию и регулярным упражнениям.”",
                        name: "- Чжанг"
                    },
                }
            }
        },
        plan: {
            label: "ТВОЙ ПЛАН",
            experience: {
               title: "Как бы вы описали ваш танцевальный опыт?",
               subtitle: "",
               labels: {
                    beginner: {
                       text: "Начинающий",
                       subtext: "Только пробую себя в танцах"
                    },
                    intermediate: {
                       text: "Средний",
                       subtext: "Я танцую иногда"
                    },
                    advanced: {
                       text: "Продвинутый",
                       subtext: "Я обучаюсь танцам"
                    }
               }
             },
             styles: {
                title: "С какими стилями вы бы хотели поработать?",
                subtitle: "",
                labels: {
                     hiphop: "Хип-Хоп",
                     kpop: "К-Поп",
                     dancehall: "Дансхолл",
                     latino: "Соло Латино",
                     belly: "Танец живота",
                     tiktok: "Tik-Tok",
                     vogue: "Вог",
                     twerk: "Тверк",
                     heels: "Хиллз",
                     jazzfunk: "Джаз-фанк"
                },
                donotknow: "Я не знаю этих стилей"
            },
            times: {
                title: "Сколько раз в неделю вы хотели бы заниматься танцами?",
                labels: {
                     0: "1-2",
                     1: "3-4",
                     2: "5 или более"
                 }

            },
            session: {
                title: "Как долго вам было бы удобно тренироваться в течение одной сессии?",
                labels: {
                     0: "10-15 минут",
                     1: "20-30 минут",
                     2: "35-45 минут"
                }
            },
            danceExercise:{
                 title: "Танцы vs Обычные упражнения",
                 subtitle: "Средний расход калорий за 30 мин",
                 info: "“Если мы посмотрим на линии сердечного ритма с Зумба фитнес сессии... вы сжигаете больше калорий по сравнению с обычными упражнениями”"
            },
            chartBanner:{
                title: "Your 4-week Dancing Weight Loss Plan is ready!",
                subtitle: "*based on the information of users who log their progress in the app",
                info: "<b>65%</b> of users who started their <b>Dancing Weight Loss Plan with Everdance</b> advanced in their goals within the <b>first month*</b>"
            },
            creatingPlan: {
                title: "The most innovative technology in the market.",
                subtitle: "Creating your personalized plan...",
                messages: [
                    {
                        firstTitle: "Personal",
                        secondTitle: " dance plan ",
                        thirdTitle: "according to your goals"
                    },
                    {
                        firstTitle: "Track your progress and",
                        secondTitle: " get achievements",
                        thirdTitle: ""
                    },
                    {
                        firstTitle: "Calories burned",
                        secondTitle: " AI ",
                        thirdTitle: "tracker"
                    }
                ]
            },
            resultProgress: {
                title: "We’ve got you! Let’s tailor your workout program to your fitness profile",
            },
            result: {
            title: "1 миллион человек",
                        subtitle: "выбирают Everdance",
                        processTitle: "Создание плана...",
                        labels: {
                             25: "Определение вашего уровня",
                             50: "Анализ ваших целей",
                             75: "Адаптация плана под ваш образ жизни",
                             100: "Ваш план готов!"
                        },
                        comments: {
                              0: {
                                 title: "Удивительно!",
                                 subtitle: "Я никогда не думала, что тренировки могут быть такими увлекательными и что с помощью приложения я смогу так сильно похудеть. Я рассказала маме, и теперь мы тренируемся вместе, и нам это нравится.",
                                 author: "Ольга Никитенко",
                              },
                              1: {
                                 title: "Я люблю это приложение",
                                 subtitle: "Ребята, с большой любовью и уважением к тому, что вы делаете! Это действительно помогает похудеть и одновременно весело провести время! Продолжайте хорошую работу.",
                                 author: "Тамара Серова",
                              },
                              2: {
                                 title: "Классное приложение",
                                 subtitle: "Великолепное приложение, очень мотивирующее, курсы на любой вкус! Как здорово иметь такое средство, которое помогает похудеть, поднять настроение и весело провести время!",
                                 author: "Юлия Кравцова"
                              }
                        }
            }
        },

        paywall: {
            greenTheme: {
                beforeTitle: "You can reach your goal",
                title: "with Personal Dance Plan",
                chartNotice: "This chart is for illustrative purposes only",
                mealPlan: {
                    titleOne: "Weight Loss Boost",
                    titleTwo: "Speed up your results with our",
                    titleThree: "Meal Plan & HIIT Workouts Program",
                    timer: "EXPIRES IN:",
                    stickyTimer: "Free bonus enhancement offer expires in",
                },
                cards: {
                    titleOne: "Grab your Personal Dance Plan",
                    titleTwo: "+ Weight Loss Boost",
                    titleThree: "before it’s gone!",
                    notifyBox: {
                        mainText: "People using Everdance for 3 months lose twice as much weight as for 1 month*",
                        subText: "*According to Everdance user research, 2023",
                        notifyText: "*You can cancel anytime. All prices are in USD."
                    }
                },
                downloadApps: {
                    title: "1,4+ Million",
                    subtitle: "users started their weight loss journey with us",
                },
                stayOnTop: {
                    titleOne: "Stay on top of your dance journey with ",
                    titleTwo: "the Everdance app",
                }
            },
                  reserved: "Скидка исчезнет через:",
                  title: {
                     start: "Ваш ",
                     personal: "персональный",
                     end: " 30-дневный план готов"
                  },
                  goal: "Цель",
                  targetWeight: "Целевой вес",
                  cancelAnytime: "*Вы можете отменить подписку в любой момент. Все цены указаны в долларах.",
                  peopleAsk: "Люди Обычно Спрашивают:",
                  love: "Пользователи любят нас",
                  get: "СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ",
                  products: {
                            "1-month plan": "1 месяц",
                            "3-month plan": "3 месяца",
                            "1-week plan": "1 неделя",
                            "6-month plan": "6 месяцев",
                  },
                  periods: {
                      "3 months": "3 месяца ",
                      "week": "1 неделю ",
                      "1 month": "1 месяц ",
                      "6 months": "6 месяцев ",
                  },
                  features: {
                    0: {
                    p1: "Доступ к +350",
                    p2:  "танцевальным",
                    p3: "тренировкам"
                    },
                    1: {
                    p1: "Персональный",
                    p2: "танцевальный",
                    p3: "план"},
                    2: "Special prize for our subscribers",
                    3: "Live dance classes 2 times a week",
                    4: "Daily useful tips&tricks",
                    5: {
                    p1: "Поддержка",
                    p2: "тренеров"
                    },
                    6: {
                    p1: "Всего 15 минут в",
                    p2: "день для отличного",
                    p3: "результата"
                    },
                    7: "Nike Dancers and Lizzo’s Grrrls classes"
                  },
                  bonus: {
                  title: "+ БЕСПЛАТНЫЙ БОНУС:",
                  mealPlan: "План Питания и ",
                  hiit: "Табата программа"
                  },
                  refund: {
                      value: "Наши пользователи ценят нас:",
                      rate: "процент возврата менее 2%*",
                      year: "*по данным 2022 года",
                      days: "30 дней ",
                      mbg: "гарантия возврата"
                  },
                  discount: {
                   previous: "Предыдущая скидка: ",
                   titleGet: "Получи свой персональный план с",
                   titleStart: "Начни свой персональный план с",
                   discount:  " скидкой"
                  },
                  cardHeader: {
                       title: {
                       specialOffer: "СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ",
                       bfOffer: "ЧЕРНАЯ ПЯТНИЦА",
                       nyOffer: "НОВОГОДНЕЕ ПРЕДЛОЖЕНИЕ"
                       },
                       imageTitle: {
                            off50: {text: "50% СКИДКА", fontSize: "11px"},
                            off60: {text: "60% СКИДКА", fontSize: "11px"},
                            trial: {text: "7-ДНЕВНЫЙ ТРИАЛ", fontSize: "16px"},
                            off70: {text: "70% СКИДКА", fontSize: "11px"},
                            off75: {text: "75% СКИДКА", fontSize: "11px"},
                            bf: {text: "ЧЕРНАЯ ПЯТНИЦА", fontSize: "11px"},
                            ny: {text: "НОВОГОДНЕЕ ПРЕДЛОЖЕНИЕ", fontSize: "11px"}
                       },
                       save: {text: "СКИДКА ", fontSize: "10px"},
                       trial: {text: "7-ДНЕВНЫЙ ТРИАЛ ", fontSize: "10px"},
                       sale: {text: "СКИДКА", fontSize: "11px"},
                       mostPopular: {text: "САМЫЙ ПОПУЛЯРНЫЙ", fontSize: "10px"},
                       perDay: "в день"
                  },

                  questions: {
                    0: {
                      title: "Можно ли похудеть с помощью танцев?",
                      subtitle: "Танцы — это отличная кардио-тренировка, которая не только помогает сжигать калории, но и помогает чувствовать себя счастливее. Похудейте и почувствуйте себя хорошо, делая это!"
                    },
                    1: {
                      title: "Чем мне могут помочь танцы?",
                      subtitle: "Танцы помогут вам сбросить лишний вес, привести мышцы в тонус, сделать вас более гибкими и улучшить здоровье сердечно-сосудистой системы. Не говоря уже о том, чтобы вы почувствовали себя счастливее!"
                    },
                    2: {
                       title: "Можно ли получить подтянутое тело с помощью танцев?",
                       subtitle: "Регулярно занимаясь танцами, можно повысить тонус мышц, избавиться от лишних килограммов и повысить самооценку."
                    },
                    3: {
                      title: "Почему танцы — это хорошая тренировка?",
                      subtitle: "Танцы подходят как профессионалам, так и новичкам в мире спорта. Для начала не требуется специальное оборудование, и они  дадут вам реальные улучшения."
                    }
                  },
                  comments: {
                    0: {
                    title: "Удивительно!",
                    subtitle: "Я никогда не думала, что тренировки могут быть такими увлекательными и что с помощью приложения я смогу так сильно похудеть. Я рассказала маме, и теперь мы тренируемся вместе, и нам это нравится.",
                    author: "Ольга Никитенко",
                    date: "21 Января"
                    },
                    1: {
                    title: "Я люблю это приложение",
                    subtitle: "Ребята, с большой любовью и уважением к тому, что вы делаете! Это действительно помогает похудеть и одновременно весело провести время! Продолжайте хорошую работу.",
                    author: "Тамара Серова",
                    date: "5 Ноября"
                    },
                    2: {
                    title: "Классное приложение",
                    subtitle: "Великолепное приложение, очень мотивирующее, курсы на любой вкус! Как здорово иметь такое средство, которое помогает похудеть, поднять настроение и весело провести время!",
                    author: "Юлия Кравцова",
                    date: "11 Октября"
                    }
                  },
                  feedbacks: {
                    0: {
                      name: "Ирина",
                      before: "27 Января, 2023",
                      after: "22 Марта, 2023",
                      weight: "- 4кг",
                      feedback: "У меня всегда были небольшие проблемы с животом, он никогда не выглядел так, как мне хотелось. Итак, по совету друзей, я начала заниматься танцами с помощью этого нового приложения и была удивлена своими результатами! Я похудела в проблемных зонах, мое тело стало более подтянутым и гибким. Кроме того, теперь я чувствую себя увереннее и лучше в целом благодаря танцевальным позитивным эмоциям! "
                    },
                    1: {
                      name: "Анна",
                      before: "1 Mая, 2023",
                      after: "15 Сентября, 2023",
                      weight: "- 12 кг",
                      feedback: "Я очень рада, что могу так легко и с удовольствием вернуться к своей форме до рождения ребенка благодаря танцевальным тренировкам. Мамы поймут, как сложно найти время для ухода за собой, когда у вас кричащий новорожденный и каждый день ощущается как День сурка. Но последние несколько месяцев я танцевала дома, и это было такое удовольствие! Изучаю новые танцевальные движения, вижу, как мое тело становится более гибким и подтянутым, и наблюдаю, как уменьшается мой живот. И самое главное, у меня улучшилось настроение и я заново открыл для себя, на что способно мое тело!"
                    },
                    2: {
                      name: "Светлана",
                      before: "5 Апреля, 2023",
                      after: "27 Июля, 2023",
                      weight: "- 7 кг",
                      feedback: "У меня всегда была стройная фигура, и я ела все, что хотела, не набирая при этом вес. Но это заставило меня слишком расслабиться в отношении своих привычек. Со временем я начала набирать вес, даже не осознавая этого. Так было до тех пор, пока мой врач не шокировал меня, сказав, что у меня разовьется диабет, если я не изменю свои привычки и не добавлю больше физической активности. Я нашла это приложение и начала тренироваться по плану. Через пару недель стала замечать, как возвращается моя привычная энергия, мое тело стало более подтянутым, и я стала чаще улыбаться."
                    }
                  },
                  featured: "О нас пишут в:",
                  usersMeetGoals: "Our Users Meet Their Goals",
                  peopleLikeYou: "Люди, которые уже достигли своих целей с ",
                  plan: "Танцевальным Планом Похудения",
                  disclaimer: "Отказ от ответственности:",
                  key: " соблюдение плана тренировок и питания — это ключ к вашему фитнес-результату. Перед стартом проконсультируйтесь с врачом.",
                  guarantee: "30 Дней Гарантия Возврата",
                  results: "Мы верим, что наш план подойдет вам и вы получите видимые результаты уже через 4 недели! ",
                  return: "Мы даже готовы вернуть вам деньги, если вы не увидите видимых результатов и сможете продемонстрировать, что следовали нашему плану.",
                  note: "Обратите внимание:",
                  subscrRenew: " подписки продлеваются автоматически в конце каждого периода, если вы их не отмените. Если вы хотите отменить подписку, напишите в нашу Службу поддержки. Для доступа к полной версии продукта вам понадобится мобильный телефон Android или iOS. Вы можете сделать снимок экрана с этой информацией и сохранить его."
                },
                payment: {
                     title: "выберите способ оплаты",
                     start: "Начните ваш план на ",
                     or:  "или",
                     after: "После успешной оплаты, пожалуйста, проверьте вашу почту.",
                     afterFirst: {
                       after: "После первого периода на ",
                       per: " за "
                     },
                     cancelAny: "Возможность отменить в любое время",
                     cardnumber: "Номер карты",
                     cardname: "Имя владельца карты",
                     email: "E-mail",
                     perDay: " в день",
                     success: "Оплата прошла успешно!",
                     checkEmail: "Пожалуйста, проверьте вашу почту, указанную во время оплаты."
                },
                registration: {
                       steps: {
                         0:  "Покупка\nплана",
                         1: "Создание\nаккаунта",
                         2: "Доступ\nк плану",
                         stepsWidth: '250px',
                         fontSize: '12px'
                        },

                        title: "Создайте аккаунт",
                        subtitle: "чтобы получить доступ к плану",
                        fullname: "Ваше полное имя",
                        email: "Ваш email",
                        password: "Пароль",
                        confirmPassword: "Подтвердите пароль",
                        emailOk: "Данный email доступен для регистрации",
                        emailExists: "Данный email уже используется",
                        emailErr: "Неверное значение email",
                        passwordLength: "Пароль должен быть не менее 6 символов",
                        passwordMatch: "Пожалуйста, убедитесь что пароль совпадает",
                        create: "Создать аккаунт",
                        bySigning: "Регистрируясь в Everdance, вы соглашаетесь с",
                        view: "Ознакомьтесь с нашими ",
                        created: "Ваш аккаунт успешно создан",
                        nextSteps: "Следуйте этим шагам, чтобы получить доступ к своему плану",
                        download: "Скачайте приложение Everdance app",
                        toPhone: "на ваше устройство",
                        use: "Используйте ",
                        login: "Log in",
                        toLogin: " чтобы авторизоваться",
                        youShould: "Вы должны нажать на кнопку Log in и ввести ваш email и пароль из предыдущего шага регистрации",
                        enter: "Введите ваш email и пароль из предыдущего шага регистрации",
                  }
    }
});

export default strings;