import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import StepSlider from '../stepSlider/StepSlider';

import styles from "./SwiperComponent.module.scss";
import 'swiper/css';
import LazyImage from "../lazyImage/LazyImage";

const SwiperComponent = props => {
    const { data, withBorder, defaultSlide, currentSlide, withTitle, sliderTitle, changeSlide } = props;
    const swiperRef = useRef();

    const swiperSlideClass = `${styles.swiperSlide} ${ !withBorder ? styles.swiperSlideBorder : ''}`

    return (
        <>
            <Swiper
                className={styles.swiperContainer}
                spaceBetween={20}
                slidesPerView={"auto"}
                centeredSlides={true}
                initialSlide={currentSlide}
                onSlideChange={slide => changeSlide(slide.activeIndex)}
                onSwiper={(swiper) => swiperRef.current = swiper}
            >
                {data?.map(slide => (
                    <SwiperSlide className={swiperSlideClass} key={slide.id} >
                        <LazyImage
                            srcWebp={slide.imageWebp}
                            src={slide.image}
                            alt=""
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            { withTitle && <span className={styles.sliderTitle}>{sliderTitle}</span> }
            <StepSlider swiperRef={swiperRef} defaultSlide={defaultSlide} currentSlide={currentSlide} sliderCount={data?.length - 1 || 0}/>
        </>
    )
}   

export default SwiperComponent;