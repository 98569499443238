import styles from "./LazyImage.module.scss";

const LazyImage = (props) => {
    const { className, srcWebp, src, alt, style, onClick } = props

    return (
        <picture className={className} style={style}>
            <source srcSet={srcWebp} type="image/webp" />
            <source srcSet={src} type="image/jpeg" />
            <img src={src} alt={alt} loading="lazy" onClick={onClick} />
        </picture>
    );
};

export default LazyImage;