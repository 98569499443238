import { useEffect, useState } from 'react';
import RegistrationStepper from "../../components/UI/registrationStepper/RegistrationStepper";
import RegistrationForm from "../../components/createAccount/registrationForm/RegistrationForm";

import stepsData from "../../core/static/createAccount/registrationSteps.json";
import styles from "./CreateAccount.module.scss";
import RegistrationResult from "../../components/createAccount/registrationResult/RegistrationResult";
import FailedRegistrationPopup from "../../components/UI/failedRegistrationPopup/FailedRegistrationPopup";
import WaitingRegistrationPopup from "../../components/UI/waitingRegistrationPopup/WaitingRegistrationPopup";

const CreateAccount = () => {
    const [activeStep, setActiveStep] = useState(2);
    const [stepComponent, setStepComponent] = useState();
    const [closedFailedRegistrationPopup, setClosedFailedRegistrationPopup] = useState(true)
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)

    const steps = stepsData;
    const domain = localStorage.getItem('domain')

    useEffect(() => {
        setClosedFailedRegistrationPopup(true)
        setDisplayWaitingRegistrationPopup(false)
    }, [])

    const tryAgainAction = () => {
        setClosedFailedRegistrationPopup(true);
    }

    const nextStep = () => {
        setActiveStep((prev) => prev + 1);
    };

    useEffect(() => {
        setStepComponent(findStep(activeStep))
    }, [activeStep])

    const findStep = (value) => {
        switch(value) {
            case 2:
                return <RegistrationForm nextStep={nextStep} setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} />;
            case 3:
                return <RegistrationResult />;
            default:
                return <RegistrationForm nextStep={nextStep} setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain}/>;
        }
    }

    return (
        <div className={`${styles.mainContainer} flex-column container`}>
            { !closedFailedRegistrationPopup ? (<FailedRegistrationPopup onSubmit={tryAgainAction} />) : null }
            { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
            <img className={styles.logoImage} loading="lazy" src="/images/Logo.svg" alt="" />
            <RegistrationStepper steps={steps} currentStep={activeStep} />
            {stepComponent}
        </div>
    )
}

export default CreateAccount;