import { useEffect } from 'react';

import { Routes, Route } from 'react-router-dom';
import { GrowthBookProvider, GrowthBook } from "@growthbook/growthbook-react";

import amplitude from 'amplitude-js';

import ReactPixel from 'react-facebook-pixel';

import TiktokPixel from 'tiktok-pixel';

import queryString from 'query-string';

import AgeGroup from "./pages/ageGroup/AgeGroup";
import Payment from './pages/payment/Payment';
import CreateAccount from "./pages/createAccount/CreateAccount";
import Failure from './pages/paymentFailure/PaymentFailure';
import Paywall from './pages/paywall/Paywall';
import Email from './pages/email/Email';
import { RecurlyPaymentForm } from './pages/payment/RecurlyPaymentForm';
import StepsComponent from "./pages/stepper/StepsComponent";
import Intro from "./pages/intro/Intro";

import React from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

import testRatesData from "./core/static/paywallTestRates.json";


const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-tbi5NRjBnDAf7aK",
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key
    });
    }
});

const campaignsList = ['a','b','bf','c','h','ny', 'sh', 'shkiki', 'shjasmine', 'shret022470', 'sh032450', 'sh032470', 'chl', 'chl_ret70', 'ret70', 'chltr', 'chl_Akilah', 'rel06', 'chln']
const themesList = ['purple']

function App() {
    const campaign = localStorage.getItem('campaign')
    const language = localStorage.getItem('lang')
    let checkoutVrs = localStorage.getItem('checkout')
    let planProgressVrs = localStorage.getItem('plan_progress')
    let country = localStorage.getItem('country')
    let partner = localStorage.getItem('partner')
    let nutrition = localStorage.getItem('nutrition')
    let specialOffer = localStorage.getItem('nutrition')
    let theme = localStorage.getItem('theme')

    country = country === "null" || country === "" ? "eu" : country
    partner = partner === "null" || partner === "" ? null : partner
    nutrition = nutrition === "null" || nutrition === "" ? null : nutrition
    specialOffer = specialOffer === "null" || specialOffer === "" ? 2 : specialOffer
    checkoutVrs = checkoutVrs === null || checkoutVrs === "" ? 1 : checkoutVrs
    planProgressVrs = planProgressVrs === null || planProgressVrs === "" || planProgressVrs === "null" || planProgressVrs === undefined
    ? 1
    : planProgressVrs

    console.log("checkoutVrs: " + checkoutVrs)
    console.log("planProgressVrs: " + planProgressVrs)

    const themeFile = themesList.indexOf(theme) > -1 ? theme : 'default'
    let themeData = require(`./core/themes/${themeFile}.json`);

    const settingsFile = campaignsList.indexOf(campaign) > -1 ? campaign : 'default'
    let data = require(`./core/campaigns/${settingsFile}.json`);
    if(data.settings.paywall.trialing && data.settings.paywall.trialing === true){
        localStorage.setItem('discountStatus', "trial");
    }
    data = {
        ...data,
        themeData: themeData,
        country: country,
        partner: partner,
        nutrition: nutrition,
        specialOffer: specialOffer
    }

    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    let l = window.location;
    let urlParts = l.host.split(".")
    let domain = l.host
    localStorage.setItem('domain', domain);
    let subdomain = urlParts.length >= 2 ? l.host.split(".")[0] : ''

    const liveMode = subdomain !== 'test'
    data = { ...data, country: country, partner: partner, liveMode: liveMode, language: language, domain: domain, planProgress: planProgressVrs }
    if(!liveMode){
        let products = {}
        if(campaign.includes('ret')){
        products[country] = testRatesData.retarget
        } else{
         products[country] = testRatesData.default
        }

        products[`${country}_a`] = testRatesData.a
        products[`${country}_b`] = testRatesData.b
        products[`${country}_trial`] = testRatesData.trial
        products[`${country}_discount`] = testRatesData.discount
        products[`${country}_n`] = testRatesData.default_n
        products[`${country}_trial_n`] = testRatesData.trial_n
        products[`${country}_discount_n`] = testRatesData.discount_n
        data = { ...data, products: products }
    }
    //const queryParams = queryString.parse(localStorage.getItem('search'));
    const userProperties = {
        utmSource: localStorage.getItem('utm_source'),
        utmMedium: localStorage.getItem('utm_medium'),
        utmCampaign: localStorage.getItem('utm_campaign'),
        utmContent: localStorage.getItem('utm_content')
    };

    const amplitudeOnInit = (instance) => {

    };

    amplitude.getInstance().onInit(amplitudeOnInit);
    const initializeAmplitude = amplitude.getInstance().init('0f240f05cd42b2b601303fa61d325c37');
    if(localStorage.getItem('user_id') !== null && localStorage.getItem('user_id') !== undefined ){
        amplitude.getInstance().setUserId(localStorage.getItem('user_id'));
    } else{
        const v4Id = uuidv4();
        localStorage.setItem('user_id', v4Id);
        amplitude.getInstance().setUserId(v4Id);
    }
    amplitude.getInstance().setUserProperties(userProperties);

    useEffect(() => {
        console.log("po");
        localStorage.removeItem('popup');
        localStorage.removeItem('gift');
        localStorage.removeItem('discountStatus');
        localStorage.removeItem('with_nutrition');
        localStorage.removeItem('type_of_diet');
        const id = Date.now();
        document.cookie = `id=${id}`;
        ReactPixel.init(domain.includes('net') ? '1085241235883188' : '239948538703054', {external_id: id});
        TiktokPixel.init('CP2RST3C77UF83EV1CUG');

        //amplitude.getInstance().onInit(amplitudeOnInit);
        //amplitude.getInstance().setUserProperties(userProperties);

        growthbook.loadFeatures();
        growthbook.setAttributes({
            "id": new Date(),
            "loggedIn": true,
            "employee": true
        });
    }, []);

    let pathValue;
    if(l.hash !== null && l.hash !== undefined && l.hash !== ""){
        let array = l.pathname.split('/');
        pathValue = array.slice(1, array.length + 1)[0];
    }
    amplitude.getInstance().setUserProperties({"subdomain": subdomain});
    amplitude.getInstance().setUserProperties({"campaign": campaign});

    return pathValue === "paywall" ? (
        <div className="App">
           <Routes>
             <Route path='/' element={
                 <GrowthBookProvider growthbook={growthbook}>
                     <Paywall data={data} />
                 </GrowthBookProvider>}
             />
             <Route path="steps" element={<StepsComponent data={data} />} />
             <Route path="paywall" element={
               <GrowthBookProvider growthbook={growthbook}>
                 <Paywall data={data} />
               </GrowthBookProvider>}
             />
             <Route path="payment" element={<Payment data={data} partner={partner} domain={domain} checkout={checkoutVrs}/>} />
//             <Route path="payment" element={<RecurlyProvider publicKey="ewr1-I0ZlpZw9YDHMXDZF0ykIVz">
//                                                  <Elements>
//                                                    <RecurlyPaymentForm />
//                                                  </Elements>
//                                                </RecurlyProvider>} />
             <Route path="registration" element={<CreateAccount />} />
             <Route path="failure" element={<Failure />} />
           </Routes>
         </div>
    ) : pathValue === "payment" ? (
        <div className="App">
          <Routes>
            <Route path='/' element={<Payment data={data} partner={partner} domain={domain} checkout={checkoutVrs}/>}
            />
            <Route path="steps" element={<StepsComponent data={data} />} />
            <Route path="email" element={<Email />} />
            <Route path="paywall" element={
              <GrowthBookProvider growthbook={growthbook}>
                <Paywall data={data} />
              </GrowthBookProvider>}
            />
            <Route path="payment" element={<Payment data={data} partner={partner} domain={domain} checkout={checkoutVrs}/>} />
            <Route path="registration" element={<CreateAccount />} />
            <Route path="failure" element={<Failure />} />
          </Routes>
        </div>
    ) : (
        <div className="App">
            <Routes>
              <Route path='/' element={
                <GrowthBookProvider growthbook={growthbook}>
                    { data.withIntro ? (<Intro data={data} />) : (<AgeGroup data={data} />) }
                </GrowthBookProvider>}
              />
              <Route path="steps" element={<StepsComponent data={data}/>} />
              <Route path="paywall" element={
                <GrowthBookProvider growthbook={growthbook}>
                  <Paywall data={data} />
                </GrowthBookProvider>}
              />
              <Route path="payment" element={<Payment data={data} partner={partner} domain={domain} checkout={checkoutVrs}/>} />
              <Route path="registration" element={<CreateAccount />} />
              <Route path="failure" element={<Failure />} />
            </Routes>
          </div>
        );
}

export default App;
