import styles from "./CircleVideoPlayer.module.scss";
import {useRef} from "react";

const CircleVideoPlayer = (props) => {
    const { id, src, srcWebm, muteVideo, handleMuteAll, poster } = props;
    const videoRef = useRef(null);

    const handleMuteUnmute = () => {
        handleMuteAll(id, !muteVideo)
        if (muteVideo) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    return (
            <div className={styles.mainContainer} >
                <video
                    ref={videoRef}
                    src={src}
                    fullscreen={false}
                    autoPlay
                    loop
                    playsInline
                    controls={false}
                    muted={muteVideo}
                    className={styles.videoContainer}
                />
                <button
                    className={styles.muteButton}
                    onClick={handleMuteUnmute}
                >
                    {muteVideo ? (
                        <img className={styles.image} src="/images/video/muted.svg" alt="" />
                    ) : (
                        <img className={styles.image} src="/images/video/unmuted.svg" alt="" />
                    )}
                </button>
            </div>
        )
  }

export default CircleVideoPlayer;
