import styles from "./TextCard.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const TextCard = props => {
    const {
        card,
        cardHeight,
        selectCard,
        activeCard,
        isBigImage,
        backgroundColor,
        borderColor
    } = props;

    const textBox = strings[card.section][card.page].labels[card.localID]
    const cardContainerActiveClass = `${styles.activeCard} ${styles[`${borderColor}ActiveCard`]}`

    const cardContainerClass = `${styles.cardContainer} ${isBigImage ? styles.cardContainerBigImage : ''} ${activeCard?.id === card.id  ? cardContainerActiveClass : ''} flex-row cardContainer ${borderColor}CardContainer`

    let cardStyles = {}
    if(cardHeight) cardStyles = {...cardStyles, height: `${cardHeight}px`}
    if(backgroundColor) cardStyles = {...cardStyles, backgroundColor: backgroundColor}

    return (
        <>
            <div
                className={cardContainerClass}
                style={cardStyles}
                onClick={() => selectCard(card)}
            >
                <LazyImage
                    className={styles.cardIcon}
                    srcWebp={card.iconWebp}
                    src={card.icon}
                    alt=""
                />
                {
                    card.message ? (
                        <div className={styles.contentContainer}>
                            <p
                                className={`${styles.cardTitle} cardTitle`}
                               style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}
                            >
                                {textBox["text"]}
                            </p>
                           { card.subtext && (
                               <span
                                   className={styles.cardSubtitle}
                                   style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}
                               >
                                   {textBox["subtext"]}
                               </span>
                           )}
                        </div>
                    ) : (
                        <p
                            className={`${styles.cardTitle} cardTitle`}
                            style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}
                        >
                            {textBox}
                        </p>
                    )
                }
            </div>
            {
                card.message && activeCard?.id === card.id ? (
                    <p className={styles.cardMessage}>
                        {textBox["subtext"]}
                    </p>
                ) : null
            }
        </>
    )
}   

export default TextCard;